<template>
  <div style="width: 1200px;margin: 0 auto;overflow: hidden">
    <div class="zcbk" style="float: left">
      <div class="xuqiu_header" style="width: 385px">
        <div class="tixing_xuqiu_parent" style="width: 385px;display: flex;justify-content: space-between">
          <div class="tixing" style="width: auto;padding-right: 30px">
            知产百科
          </div>
          <div class="font-wp" @click="goKnowledgeList">更多>></div>
        </div>
      </div>
      <div class="zcbk_content">
        <div v-for="item in data&&data.labels[0].objs.length>5?data.labels[0].objs.slice(0,5):data.labels[0].objs"
             :key="item.id"
             style="cursor: pointer;padding:20px 10px;height: 85px;line-height:85px;border-bottom: 1px solid #f5f5f5">
          <router-link :to="{path:'knowledgeDetail',query:{id:item.id}}" target="_blank">
            <img style="float: left;height: 40px;width: 40px" :src="item.pic">
            <div style="float: right">
              <div class="ellipse-1"
                   style="height: 20px;margin-bottom:5px;width:305px;line-height: 20px;font-size: 16px;color: rgb(51, 51, 51);font-weight: bold">
                <span>{{item.name}}</span>
              </div>
              <div class="ellipse-1"
                   style="height: 20px;width:305px;line-height: 20px;font-size: 14px;color: rgb(102,102,102)"><span>{{item.introduction}}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="mtbd" style="float: right">
      <div class="xuqiu_header" style="width: 795px">
        <div class="tixing_xuqiu_parent" style="width: 795px;display: flex;justify-content: space-between">
          <div class="tixing" style="width: auto;padding-right: 30px" >
            媒体报道
          </div>
          <div class="font-wp" @click="goMediaReportList">更多>></div>
        </div>
      </div>
      <div class="mtbd_content">
        <div class="image_content" style="width: 415px;border-bottom: 1px solid rgb(245, 245, 245)">
          <img style="height: 280px;width: 100%" :src="data&&data.pic"/>


          <div v-if="data&&data.labels[1].objs.length>=4"
               style="cursor: pointer;height: 140px;border-bottom: 1px solid #f5f5f5">
            <router-link :to="{path:'/home/mediaReportDetail',query:{id:data.labels[1].objs[3].id}}" target="_blank" style="color: #666666"
                      >
              <div style="height: 40px;line-height: 40px;display: flex;justify-content: space-between;padding-right: 10px">
                <span style="font-size:16px;color:rgb(51,51,51);font-weight:bold;width: 270px" class="ellipse-1">{{data.labels[1].objs[3].name}}</span>
                <span style="  font-size: 14px;color: rgb(130, 131, 138)" class="ellipse-1">{{data.labels[1].objs[3].createdTime.split(" ")[0]}}</span>
              </div>
              <div class="ellipse-2">

              <span
                class="ellipse-3">{{data.labels[1].objs[3].introduction}}</span>
              </div>
            </router-link>
          </div>

        </div>
        <div class="only_content">
          <div
            v-for="item in data&&data.labels[1].objs.length>3?data.labels[1].objs.slice(0,3):data.labels[1].objs"
            :key="item.id" style="height: 140px;border-bottom: 1px solid #f5f5f5;padding: 20px 20px;padding-right:0;cursor: pointer">
            <router-link :to="{path:'/home/mediaReportDetail',query:{id:item.id}}" target="_blank" style="color: #666666" >
              <div style="height: 40px;line-height: 40px;display: flex;justify-content: space-between">
                <span style="font-size:16px;color:rgb(51,51,51);font-weight:bold;width: 270px" class="ellipse-1">{{item.name}}</span>
                <span style="  font-size: 14px;color: rgb(130, 131, 138);">{{item.createdTime.split(" ")[0]}}</span>
              </div>
              <div class="ellipse-3">
                <span>{{item.introduction}}</span>
              </div>
            </router-link>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  export default {
    name: 'wikiAndReport',
    props: {
      data: Object
    },

    data() {
      return {}
    },
    methods: {
      goKnowledgeList() {
        this.$router.push({
          path: '/home/baike'
        })
      },
      goMediaReportList() {
        this.$router.push({
          path: '/home/media-report'
        })
      }
    }
  }
</script>

<style>
  .zcbk_content {
    width: 385px;
    height: 420px;
    border: 1px solid #f5f5f5;
  }


  .mtbd_content {
    height: 420px;
    width: 795px;
  }

  .image_content, .only_content {
    height: 100%;
    width: 380px;
    float: left;
  }

  .font-wp {
    font-size: 16px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
  }

  .zcbk_content > div:hover {
    box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  }

  .only_content > div:hover {
    box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  }

  .image_content > div:hover {
    box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  }
</style>
